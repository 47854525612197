import { type CONVEYANCE_TYPES, type CheckoutBasket, type Location } from '@koala/sdk';
import { useEffect, useState } from 'react';
import {
  StyledDayTimeSelectContainer,
  StyledLoader,
  StyledNoAvailability,
  StyledTimeSelect,
} from './styles';
import { getHandoffTimes, useHandoffTimes } from './use-handoff-times';
import { getAvailableDays, getTimeslot } from './utils';
import StringAccessor from '@/components/cmsConfig/stringAccessor';
import GenericErrorBoundary from '@/components/genericErrorBoundary';
import { Select } from '@/components/uielements/forms/select';
import { DATE_FORMAT } from '@/constants/dates';
import { DELIVERY_TIME_WANTED_MODES } from '@/constants/global';
import { useSelector } from '@/redux';
import { formatDate, getSanitizedOffset } from '@/utils/dates';

interface Props {
  /** Whether or not the handoff time is currently being updated. */
  isSubmitting: boolean;
  /** Callback to fire when the selected handoff time has changed. */
  onChange: ((value: string) => void) | ((value: string) => Promise<void>);
  /** The currently selected handoff time. */
  value?: string;
  checkoutBasket?: CheckoutBasket;
  location: Location;
  handoffType: CONVEYANCE_TYPES;
  supportsAsap: boolean;
  stack?: boolean;
}

export function HandoffTimePicker({
  isSubmitting,
  onChange,
  value,
  checkoutBasket,
  location,
  handoffType,
  supportsAsap,
  stack = true,
}: Props) {
  const strings = useSelector((state) => state.app.cmsConfig.strings);

  /**
   * Find the initial value for the handoff day based on the basket's current
   * handoff time. This will populate the day selector on first render.
   *
   * - If a value is present (which means a user selected a time previously):
   *  - ensure the value isn't "asap" (a non time specific wanted at time)
   *  - format the day in local time as YYYY-MM-DD. This is important as all wanted at times (both selected and returned from the API)
   *    are in UTC, but are displayed in local time (the day value must be calculated in the same timezone as the user)
   * - Otherwise, grab todays date as YYYY-MM-DD in local time.
   */
  const initialDay =
    value && value !== DELIVERY_TIME_WANTED_MODES.ASAP
      ? // formats the time in a specific timezone
        formatDate(value, DATE_FORMAT.YEAR_MONTH_DAY_DASHED)
      : // formats the time in the user's local timezone
        formatDate(new Date(), DATE_FORMAT.YEAR_MONTH_DAY_DASHED);

  const [selectedDay, setSelectedDay] = useState(initialDay);

  // Retrieve available handoff times for the current order.
  const { data, isLoading, isError } = useHandoffTimes({
    basketId: checkoutBasket?.id,
    dayWanted: selectedDay,
    handoffType,
    locationId: location.id,
    supportsAsap,
  });

  const isDayOfWeekIncludedInOperatingHours = (dayOfWeek: string /* Tue, Sep 10 */) => {
    const operatingHours = location?.operating_hours;
    if (!operatingHours) return;

    const day = dayOfWeek.slice(0, 3).toLowerCase(); // "Tue"

    // Create an array of abbreviated day_of_week's and then check with some
    const isMissing = operatingHours
      .map((hours) => hours.day_of_week.slice(0, 3).toLowerCase())
      .some((dayOfWeekAbbreviation) => dayOfWeekAbbreviation === day);

    return isMissing; // If no match is found, the day is missing from operating_hours && isClosed
  };

  /**
   * Fires when the user selects a different handoff day.
   * It's somewhat complex because we must:
   * a) find available times for the selected day
   * b) set the basket's handoff time to the first available slot on that day
   */
  async function handleDaySelect(day: string) {
    // Optimistically update the dropdown menu.
    setSelectedDay(day);
    // Fetch handoff times for the new day.
    const res = await getHandoffTimes({
      basketId: checkoutBasket?.id,
      dayWanted: day,
      handoffType,
      locationId: location.id,
      supportsAsap,
    });
    // If times are available, set the basket's handoff to the first slot.
    if (res.length > 0) {
      onChange(res[0]);
    } else {
      // If no times are available, reset the basket's handoff time.
      const userGMTOffset = getSanitizedOffset(-(new Date().getTimezoneOffset() / 60));
      onChange(day ? `${day}T00:00:00${userGMTOffset}` : '');
    }
  }

  // Get a list of days that the order can be scheduled for.
  const availableDays = getAvailableDays({
    canOrderAhead: location.order_ahead,
    advanceDays: location.order_ahead_days,
  });
  //`disable` any day the brand is closed for business
  const days = availableDays.map((day) => {
    if (location?.operating_hours) {
      return isDayOfWeekIncludedInOperatingHours(day.label) ? day : { ...day, disabled: true };
    } else {
      return day;
    }
  });

  // Format the list of handoff times for a given day.
  const timeslots = data?.map(getTimeslot(strings, checkoutBasket)) ?? [];

  // Set value to the first available timeslot if there is no initial value set in store where values is coming from in parent component.
  useEffect(() => {
    if (!value && timeslots.length > 0) {
      onChange(timeslots[0]?.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeslots.length, value]);

  useEffect(() => {
    if (value && value !== DELIVERY_TIME_WANTED_MODES.ASAP) {
      // formats the time in a specific timezone
      setSelectedDay(formatDate(value, DATE_FORMAT.YEAR_MONTH_DAY_DASHED));
    } else {
      setSelectedDay(formatDate(new Date(), DATE_FORMAT.YEAR_MONTH_DAY_DASHED));
    }
  }, [value]);

  return (
    <GenericErrorBoundary>
      <StyledDayTimeSelectContainer $stack={stack}>
        <Select
          name="day_wanted"
          placeholder="Date Wanted"
          onChange={handleDaySelect}
          value={selectedDay}
          options={days}
          title="Date Wanted"
        />
        {(!data || data.length !== 0) && (
          <StyledTimeSelect $showLoading={isLoading || isSubmitting} $showError={isError}>
            <Select
              name="time_wanted"
              placeholder="Time Wanted Mode"
              onChange={onChange}
              // value could be empty string so we set it to first available timeslot
              // it works along with useEffect above
              value={value || timeslots?.[0]?.value}
              options={timeslots}
              title="Time Wanted"
              errorMessage={isError ? 'Please select a different time.' : undefined}
            />
            {(isLoading || isSubmitting) && <StyledLoader />}
          </StyledTimeSelect>
        )}
        {data && data.length === 0 && (
          <StringAccessor
            tag={StyledNoAvailability}
            accessor="handoff_time.no_available_times"
            html={true}
          />
        )}
      </StyledDayTimeSelectContainer>
    </GenericErrorBoundary>
  );
}
