import { getFooter, type BearerToken } from '@koala/sdk/v4';
import { useQuery } from '@tanstack/react-query';
import { createHttpClientWithToken } from './client';
export interface FooterLink {
  url: string;
  text: string;
  external?: boolean;
}

export interface FooterTemplate {
  copyright: string | null;
  background_color: string | null;
  copyright_color: string | null;
  link_color: string | null;
  columns: Record<string, FooterLink[]>;
}

export async function getFooterTemplate({
  origin,
  orgId,
  token,
}: {
  origin: string;
  orgId?: number | null;
  token?: BearerToken | null;
}): ReturnType<typeof getFooter> {
  const client = createHttpClientWithToken({ origin, token });

  return await getFooter({ client, organizationId: orgId });
}

export function useFooterQuery(origin: string, orgId: number | null) {
  return useQuery({
    queryKey: ['site-footer'],
    queryFn: () => getFooterTemplate({ origin, orgId }),
    refetchOnWindowFocus: false,
    staleTime: 60 * 1000,
  });
}
